import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

export default function initCarouselProduits() : void {
    const $carouselProduitsContainer = document.querySelectorAll<HTMLElement>('.block-carousel-produits');

    $carouselProduitsContainer.forEach($container => {
        new CarouselProduits($container);
    });
}

class CarouselProduits {
    $el: HTMLElement | null | undefined;
    $slider: HTMLElement | null | undefined;
    $pagination: HTMLElement | null | undefined;
    swiper: Swiper | null = null;

    constructor($el: HTMLElement | null) {
        this.$el = $el;
        this.$slider = $el?.querySelector('.carousel-produits');
        this.$pagination = $el?.querySelector('.carousel-produits-pagination');
        this.init();
    }

    init(): void {
        
        if (this.$slider) {
            this.swiper = new Swiper(this.$slider, {
                modules: [Navigation, Pagination],
                slidesPerView: 'auto',
                allowTouchMove: true,
                spaceBetween: 20,
                grabCursor: true,
                pagination: {
                    el: this.$pagination,
                    clickable: true
                },
            });
        }
    }
}
