// import initModals from './components/modal';
import initAppearAnim from './utils/appear-anim';
import initNav from './components/nav';
// import initDropdownGrid from './utils/dropdown-grid';
import initDropdown from './utils/dropdown';
import initInputCustom from './components/input-custom';
// //import { initYoutubeIframe } from './components/youtube-iframe';
// import initMaillagePostsSimilaires from './template-parts/maillage-posts-similaires';
// import initSingleContentSummary from './template-parts/single-content-summary';
// import initTypeContent from './blocks/type-content';
import initAccordeon from './blocks/accordeon';
// import initGridPostsSwiper from "./components/grid-posts";
import initCarouselVignettes from './blocks/carousel-vignettes';
import initCarouselProduits from './blocks/carousel-produits';
import initCarouselThemes from './blocks/carousel-themes';
import initCarouselImagesPleineLargeur from './blocks/carousel-citations';
import initCarouselCategories from './blocks/carousel-categories';
// import initTabs from './blocks/tabs';
// import initTabsVertical from './blocks/tabs-vertical';
// import initVideo from './blocks/video';
// import initScrollLogos from './blocks/liste-logos';
// import initDerniersPosts from './blocks/derniers-posts';
import initWoocommerceNotifs from './components/woocommerce-notifications';

// Fix de la valeur de "100vh" pour les appareils iOS + Fix de la valeur de "100vw"
function appHeight() {
    const vh = (window.innerHeight * 0.01) * 100;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    const vw = document.body.clientWidth;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
}
window.addEventListener('load', appHeight);
window.addEventListener('resize', appHeight);


// Utils
initAppearAnim();
// initDropdownGrid();
initDropdown();

// Modules
initNav();

// Blocks
// initTabsVertical();
// initTabs();
// initTypeContent();
initAccordeon();
initCarouselVignettes();
initCarouselProduits();
initCarouselThemes();
initCarouselImagesPleineLargeur();
initCarouselCategories();
// initVideo();
// initScrollLogos();
// initDerniersPosts();
// initImplantationOnglets();

//TEMPLATE PARTS
// initMaillagePostsSimilaires();
// initSingleContentSummary();

// Components (En dernier sinon les bouton modals dans un swiper ne s'initialise pas)
initInputCustom();
// initModals();
//initYoutubeIframe();
// initGridPostsSwiper();

initWoocommerceNotifs();


// PRICE UPDATER
initPriceUpdater();


function initPriceUpdater() {
    const priceElement = document.querySelector<HTMLElement>('.price .woocommerce-Price-amount');
    const quantityInput = document.querySelector<HTMLInputElement>('.quantity input.qty');

    if (priceElement && quantityInput) {
        // Obtention du prix unitaire à partir du texte du prix
        const unitPriceText = priceElement.textContent?.replace('€', '').trim();
        const unitPrice = parseFloat(unitPriceText?.replace(',', '.') || '0');

        if (!isNaN(unitPrice)) {
            quantityInput.addEventListener('input', () => {
                updatePrice(priceElement, unitPrice, quantityInput);
            });
        }
    }
}

function updatePrice(priceElement: HTMLElement, unitPrice: number, quantityInput: HTMLInputElement) {
    // Obtenez la quantité actuelle
    const quantity = parseInt(quantityInput.value, 10);
    
    // Assurez-vous que la quantité est un nombre valide
    if (!isNaN(quantity) && quantity > 0) {
        // Calculez le nouveau prix total
        const totalPrice = (unitPrice * quantity).toFixed(2).replace('.', ',');

        // Mettez à jour le texte du prix affiché
        priceElement.innerHTML = `<bdi>${totalPrice} <span class="woocommerce-Price-currencySymbol">€</span></bdi>`;
    } else {
        // Réinitialisez le prix si la quantité n'est pas valide
        priceElement.innerHTML = `<bdi>${unitPrice.toFixed(2).replace('.', ',')} <span class="woocommerce-Price-currencySymbol">€</span></bdi>`;
    }
}

