import { toggleClassWhenSticky } from "../utils/toggle-class-when-sticky";

export default function initNav(): void {
    const $navContainer = document.querySelectorAll<HTMLElement>('.js-main-header');

    $navContainer.forEach($container => {
        new Nav($container);
    });
}

class Nav {
    $body: HTMLElement | null | undefined;
    $el: HTMLElement | null | undefined;
    $burgerBtn: HTMLElement | null | undefined;
    $mobileMenu: HTMLElement | null | undefined;
    $navLinks: NodeListOf<HTMLElement> | null | undefined;
    $subMenus: NodeListOf<HTMLElement> | null | undefined;
    device: string | null;
    baseUrl: string | null;
    navBreakpoint: number;

    constructor($el: HTMLElement | null) {
        this.$body = document.body;
        this.$el = $el;
        this.$navLinks = this.$el.querySelectorAll('.header-nav-link');
        this.$burgerBtn = this.$el?.querySelector('.js-burger-btn');
        this.$mobileMenu = this.$el?.querySelector('.main-header-menu');
        this.$subMenus = this.$el?.querySelectorAll('.has-submenu');
        this.navBreakpoint = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-nav'));
        this.device = null;
        this.baseUrl = window.location.origin;
        window.addEventListener('resize', this.onWindowResize.bind(this));
        this.onWindowResize();
        this.init();
    }


    onWindowResize(): void {
        if (this.device != 'mobile' && window.innerWidth < this.navBreakpoint) {
            this.device = 'mobile';
            this.$el?.classList.add('header-mobile');
            this.burgerButtonHandler();
            this.toggleSubMenuMobile();
            this.addAnchorListeners();
        } else if (this.device != 'desktop' && window.innerWidth > this.navBreakpoint) {
            this.device = 'desktop';
            this.$el?.classList.remove('header-mobile');
            this.burgerButtonRemoveHandler()
            this.toggleSubMenuDesktop();
        }
    }

    init(): void {
        if (this.$el) {
            toggleClassWhenSticky(this.$el, 'main-header-sticky');
        }
    }

    burgerButtonHandler() : void {
        this.$burgerBtn?.addEventListener('click', this.toggleMenu.bind(this));
    }

    burgerButtonRemoveHandler() : void {
        this.$burgerBtn?.removeEventListener('click', this.toggleMenu.bind(this));
    }


    toggleMenu(): void {
        this.$mobileMenu?.classList.toggle('main-header-menu--active');
        this.$body?.classList.toggle('prevent-scroll');
        this.$body?.classList.toggle('main-header-menu-is-active');
        this.$subMenus?.forEach(subMenu => subMenu.classList.remove('has-submenu--active'))
    }

    toggleSubMenuDesktop(): void {
        const $subMenusBtns = this.$el?.querySelectorAll('.has-submenu .header-nav-link');
        let activeButton = null;

        $subMenusBtns?.forEach((subMenuBtn) => {
            subMenuBtn?.addEventListener('keydown', (event) => {
                if (event['key'] === ' ' || event['key'] === 'Spacebar' || event['keyCode'] === 32 || event['keyCode'] === 13 || event['key'] === 'Enter') {
                    // Barre d'espace enfoncée
                    event.preventDefault(); // Empêche le défilement de la page
                    if (subMenuBtn !== activeButton) {
                        // Un autre bouton a actuellement la classe active
                        if (activeButton) {
                            activeButton.classList.remove('active');
                        }
                        subMenuBtn.classList.add('active');
                        activeButton = subMenuBtn;
                    } else {
                        subMenuBtn.classList.remove('active');
                        activeButton = null
                    }
                }
            })
        })

        document.addEventListener('keydown', (event) => {
            if (event['key'] === 'Escape' || event['key'] === 'Esc' || event['keyCode'] === 27) {
                $subMenusBtns?.forEach((subMenuBtn) => {
                    subMenuBtn.classList.remove('active');
                    activeButton = null;
                });
            }
        });
    }

    toggleSubMenuMobile(): void {
        const $headerMobile = this.$body?.querySelector('.js-main-header.header-mobile');

        if ($headerMobile) {
            const $subMenus = $headerMobile.querySelectorAll('.header-nav-element.has-submenu');
            $subMenus?.forEach((subMenu) => {
                const link = subMenu.querySelector('.header-nav-link');
                link?.addEventListener('click', () => {
                    subMenu.classList.toggle('has-submenu--active');

                    const $backButton = subMenu.querySelector('.back-button-menu');
                    
                    $backButton?.addEventListener('click', () => {
                        subMenu.classList.remove('has-submenu--active');
                    })
                })
            })
        }
    }

    addAnchorListeners(): void {
        this.$navLinks?.forEach(link => {
            link.addEventListener('click', this.handleNavLinkClick.bind(this));
        });
    }

    removeAnchorListeners(): void {
        this.$navLinks?.forEach(link => {
            link.removeEventListener('click', this.handleNavLinkClick.bind(this));
        });
    }

    handleNavLinkClick(event: MouseEvent): void {
        const target = event.currentTarget as HTMLAnchorElement;
        const href = target.getAttribute('href');

        if (href && (href.startsWith('#') || href.startsWith(`${this.baseUrl}/#`))) {
            this.toggleMenu();
        }
    }
}
