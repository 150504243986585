import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

export default function initCarouselCategories() : void {
    const $carouselCategoriesContainer = document.querySelectorAll<HTMLElement>('.block-carousel-categories');

    $carouselCategoriesContainer.forEach($container => {
        new CarouselCategories($container);
    });
}

class CarouselCategories {
    $el: HTMLElement | null | undefined;
    $slider: HTMLElement | null | undefined;
    $navigation_prev: HTMLElement | null | undefined;
    $navigation_next: HTMLElement | null | undefined;
    $pagination: HTMLElement | null | undefined;
    swiper: Swiper | null = null;

    constructor($el: HTMLElement | null) {
        this.$el = $el;
        this.$slider = $el?.querySelector('.carousel-categories');
        this.$navigation_prev = $el?.querySelector('.button-prev');
        this.$navigation_next = $el?.querySelector('.button-next');
        this.$pagination = $el?.querySelector('.carousel-categories-pagination');
        this.init();
    }

    init(): void {
        
        if (this.$slider) {
            this.swiper = new Swiper(this.$slider, {
                modules: [Navigation, Pagination],
                slidesPerView: 'auto',
                allowTouchMove: true,
                spaceBetween: 30,
                grabCursor: true,
                navigation: {
                    nextEl: this.$navigation_next,
                    prevEl: this.$navigation_prev,
                },
                pagination: {
                    el: this.$pagination,
                    clickable: true
                },
            });
        }
    }
}
