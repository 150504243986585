
export default function initWoocommerceNotifs() {
    const $notifications = document.querySelectorAll<HTMLElement>('.woocommerce-notif');
    $notifications.forEach($elem => {
        new WoocommerceNotification($elem);
    });
}

/**
 * @param {HTMLElement} $el           HTML Element Input Custom Wrapper
 * @param {HTMLInputElement} $input        HTML Input Element Input field
 * @param {HTMLInputElement} $textarea       HTML Input Element Input field
 */
class WoocommerceNotification {
    $el: HTMLElement | null | undefined;
    $closeBtn: HTMLElement | null | undefined;
    
    constructor($el: HTMLElement | null) {
        this.$el = $el;
        this.$closeBtn = $el.querySelector('.close-button');

        this.init();
    }

    init() {
        if (this.$closeBtn) {
            this.$closeBtn.addEventListener('click', () => {
                this.$el.classList.add('hidden');
            })
        }
    }
}
