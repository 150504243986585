
export default function initAccordeon() {
    const $blocksAccordeon = document.querySelectorAll<HTMLElement>('.block-accordeon');
    $blocksAccordeon.forEach($container => {
        new BlockAccordeon($container);
    });
}

/**
 * @param {HTMLElement} $el           HTML Element Block nos offres
 */
class BlockAccordeon {
    $el: HTMLElement | null | undefined;
    $allDropdown: NodeListOf<HTMLElement> | null | undefined;
    
    constructor($el: HTMLElement | null) {
        this.$el = $el;
        this.$allDropdown = this.$el?.querySelectorAll('.dropdown');
 
        this.init();
    }

    init() {
        this.$allDropdown?.forEach((targetDropdown) => {
            targetDropdown.addEventListener("click", () => {
                this.$allDropdown?.forEach((detail) => {
                    if (detail !== targetDropdown) {
                        detail.removeAttribute("open");
                    }
                });
            });
        });
    }
}
